import React from "react"
import PropTypes from "prop-types"

const possibleTypes = {
  womenConstruction: {
    emoji: "👷🏻‍♀",
    description: "Women Construction WOrker",
  },
}
const possibleSizes = {
  xl: "8rem",
  l: "4rem",
  s: "2rem",
}

function Emoji({ size, type }) {
  let fontSize = possibleSizes[size] || possibleSizes.s
  const emojiInfo = possibleTypes[type]

  if (!emojiInfo) {
    return null
  }

  return (
    <span
      role="img"
      aria-label={emojiInfo.description}
      style={{
        fontSize,
      }}
    >
      {emojiInfo.emoji}
    </span>
  )
}

Emoji.defaultProps = {
  size: "s",
}

Emoji.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string.isRequired,
}

export default Emoji

import React from "react"

import Emoji from "../components/Emoji"
import Layout from "../components/layout"
import Meta from "../components/Meta"

function HomePage() {
  return (
    <Layout>
      <Meta title="Under Construction" />
      <h1 className="header">Under Construction</h1>
      <div>
        <Emoji size="xl" type="womenConstruction" />
      </div>
    </Layout>
  )
}

export default HomePage
